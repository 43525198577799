<!-- eslint-disable max-len -->
<template>
    <svg width="200" height="160" viewBox="0 0 200 160" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M176.891 15.0294C168.285 5.89181 156.437 0.495433 143.894 0.00012207V48.0265L176.891 15.0294Z" fill="#3F3D56"/>
        <path d="M176.891 21.0094L143.894 54.0065H190.009C190.025 41.7332 185.33 29.9218 176.891 21.0094Z" fill="#E4E4E4"/>
        <path d="M190.058 57.4575C190.06 60.4509 189.783 63.438 189.231 66.38C189.188 66.6129 189.143 66.8436 189.095 67.0742C188.054 72.2061 186.174 77.1315 183.532 81.6525C183.392 81.895 183.246 82.1375 183.101 82.3752V82.3776C180.497 86.6626 177.241 90.5151 173.449 93.7963C173.261 93.9603 173.074 94.122 172.883 94.2813C164.735 101.136 154.534 105.079 143.893 105.484V57.4575H190.058Z" fill="#F0F0F0"/>
        <path d="M139.229 105.526C139.867 105.526 140.502 105.509 141.134 105.485V57.4563L106.206 92.3844C115.121 100.837 126.944 105.542 139.229 105.526Z" fill="#CACACA"/>
        <path d="M139.294 5.93726C112.746 5.93726 91.2251 27.4586 91.2251 54.0065H141.249V5.98011C140.601 5.95414 139.949 5.93726 139.294 5.93726Z" fill="#1E92FC"/>
        <path d="M127.826 57.4575L117.72 67.5639L117.39 67.8944L117.382 67.9015L108.569 76.7122L108.234 77.0474L98.7127 86.569L98.3774 86.9042V86.9066L92.8975 92.3841C88.1393 87.897 84.3497 82.4836 81.762 76.477C79.1743 70.4704 77.8433 63.9978 77.8509 57.4575H127.826Z" fill="#1E92FC"/>
        <path d="M49.4197 101.206C49.7875 100.983 50.1018 100.682 50.3406 100.324C50.5794 99.9662 50.7369 99.5604 50.802 99.1352C50.867 98.71 50.838 98.2757 50.7171 97.863C50.5961 97.4502 50.3861 97.0689 50.1019 96.7461L50.4986 75.1267L45.7605 75.5236L45.7716 96.6005C45.2609 97.1139 44.9563 97.7969 44.9155 98.5199C44.8746 99.2429 45.1005 99.9558 45.5501 100.523C45.9998 101.091 46.6421 101.474 47.3553 101.6C48.0685 101.726 48.803 101.585 49.4197 101.206Z" fill="#FFB6B6"/>
        <path d="M51.2455 80.4188L45.0489 79.828C44.8726 79.8112 44.702 79.7566 44.5487 79.6679C44.3954 79.5792 44.263 79.4585 44.1606 79.314C44.0581 79.1695 43.988 79.0047 43.955 78.8307C43.9221 78.6567 43.9269 78.4776 43.9694 78.3056L45.4574 72.2808C45.5486 71.3793 45.9935 70.5507 46.6947 69.9766C47.3958 69.4026 48.296 69.13 49.1978 69.2187C50.0996 69.3074 50.9295 69.75 51.5054 70.4496C52.0814 71.1491 52.3564 72.0485 52.2702 72.9506L52.5933 79.1279C52.6025 79.3048 52.5735 79.4816 52.5082 79.6462C52.4429 79.8109 52.3429 79.9595 52.215 80.082C52.0871 80.2045 51.9343 80.298 51.767 80.3561C51.5997 80.4143 51.4219 80.4357 51.2455 80.4188Z" fill="#E4E4E4"/>
        <path d="M40.9394 157.455H43.3437L44.4875 148.181L40.939 148.181L40.9394 157.455Z" fill="#FFB6B6"/>
        <path d="M40.3259 156.67L45.061 156.669C45.8613 156.669 46.629 156.987 47.1948 157.553C47.7607 158.119 48.0787 158.887 48.0787 159.687V159.785L40.3261 159.785L40.3259 156.67Z" fill="#2F2E41"/>
        <path d="M63.1005 157.455H65.505L66.6487 148.181L63.1002 148.181L63.1005 157.455Z" fill="#FFB6B6"/>
        <path d="M62.4873 156.67L67.2224 156.669C68.0226 156.669 68.7903 156.987 69.3562 157.553C69.9221 158.119 70.2401 158.887 70.2401 159.687V159.785L62.4874 159.785L62.4873 156.67Z" fill="#2F2E41"/>
        <path d="M60.1977 82.95L61.7665 92.952L46.8616 93.1481L48.8227 83.5384L60.1977 82.95Z" fill="#FFB6B6"/>
        <path d="M61.3338 90.5098C66.0632 98.7479 66.4669 126.682 66.6693 155.533H62.649L53.0392 113.446L44.214 154.435H40.2916L43.0373 108.347C43.0373 108.347 42.2276 99.0969 46.3713 93.6384L47.3799 90.7244L61.3338 90.5098Z" fill="#2F2E41"/>
        <path d="M49.3339 88.474C49.2469 88.4739 49.1601 88.4654 49.0748 88.4484C48.7878 88.391 48.5275 88.2412 48.3336 88.022C48.1398 87.8027 48.023 87.526 48.0012 87.2341C47.8537 85.2156 47.1232 81.7019 45.8299 76.7905C45.3933 75.1213 45.49 73.3573 46.1065 71.7458C46.7229 70.1343 47.8282 68.7561 49.2673 67.8044C50.7065 66.8527 52.4074 66.3752 54.1316 66.4389C55.8558 66.5025 57.5168 67.1041 58.8819 68.1593C59.7559 68.8288 60.4854 69.6683 61.0264 70.6272C61.5674 71.5861 61.9087 72.6446 62.0298 73.7389C62.6726 79.3995 61.4608 85.0828 60.91 87.2635C60.8376 87.5482 60.6739 87.8013 60.444 87.984C60.214 88.1668 59.9305 88.2691 59.6368 88.2754L49.3625 88.4737C49.353 88.4739 49.3435 88.474 49.3339 88.474Z" fill="#E4E4E4"/>
        <path d="M78.0386 70.3153C77.9769 70.4537 77.9262 70.5968 77.887 70.7432L65.0264 75.1133L62.5414 72.9424L58.7749 76.2645L62.8007 80.5352C63.1259 80.8803 63.5543 81.1105 64.0215 81.1913C64.4887 81.2721 64.9695 81.1993 65.3917 80.9836L79.1533 73.9542C79.651 74.2594 80.2298 74.406 80.8127 74.3743C81.3957 74.3425 81.9552 74.1341 82.4168 73.7766C82.8784 73.4192 83.2202 72.9297 83.3968 72.3732C83.5734 71.8167 83.5765 71.2197 83.4055 70.6615C83.2345 70.1032 82.8976 69.6103 82.4396 69.2482C81.9816 68.8861 81.4242 68.6721 80.8416 68.6345C80.259 68.5969 79.6787 68.7376 79.178 69.0379C78.6773 69.3381 78.2799 69.7837 78.0386 70.3153Z" fill="#FFB6B6"/>
        <path d="M64.5782 75.3522L59.8899 79.447C59.7565 79.5635 59.5996 79.6499 59.4298 79.7002C59.26 79.7506 59.0814 79.7638 58.906 79.7389C58.7307 79.7141 58.5627 79.6517 58.4137 79.556C58.2646 79.4604 58.1379 79.3338 58.0422 79.1847L54.6888 73.9629C54.0965 73.2771 53.8004 72.3843 53.8654 71.4805C53.9303 70.5766 54.3511 69.7354 55.0353 69.1413C55.7196 68.5472 56.6115 68.2486 57.5155 68.3111C58.4196 68.3737 59.2619 68.7921 59.8579 69.4748L64.5669 73.4859C64.7017 73.6008 64.8101 73.7434 64.8848 73.904C64.9595 74.0645 64.9988 74.2393 64.9998 74.4164C65.0009 74.5936 64.9638 74.7688 64.891 74.9303C64.8183 75.0918 64.7115 75.2356 64.5782 75.3522Z" fill="#E4E4E4"/>
        <path d="M58.281 61.8854C59.9504 58.8314 58.828 55.0023 55.774 53.3328C52.72 51.6634 48.8909 52.7857 47.2214 55.8397C45.552 58.8937 46.6744 62.7229 49.7284 64.3923C52.7824 66.0618 56.6115 64.9394 58.281 61.8854Z" fill="#FFB6B6"/>
        <path d="M54.486 50.1398H53.208C52.5947 49.9478 51.9556 49.8505 51.3129 49.8513C51.2069 49.8513 51.1017 49.8539 50.997 49.8591C49.8022 49.9343 48.6598 50.3765 47.7257 51.1253C46.7917 51.8742 46.1116 52.8931 45.7783 54.0429C44.3494 58.8126 44.9666 64.0901 44.9666 64.0901C47.0522 65.0334 50.2398 65.7315 54.9476 65.1574L55.6399 62.2554L55.5821 65.0709C56.2457 64.9786 56.938 64.8594 57.6592 64.7132V58.5054H60.5438C60.5438 53.8841 59.1073 50.1398 54.486 50.1398Z" fill="#2F2E41"/>
        <path d="M198.509 105.795H107.93C107.867 105.795 107.806 105.77 107.762 105.725C107.717 105.681 107.692 105.62 107.692 105.557C107.692 105.494 107.717 105.434 107.762 105.389C107.806 105.345 107.867 105.32 107.93 105.32H198.509C198.572 105.32 198.633 105.345 198.677 105.389C198.722 105.434 198.747 105.494 198.747 105.557C198.747 105.62 198.722 105.681 198.677 105.725C198.633 105.77 198.572 105.795 198.509 105.795Z" fill="#F0F0F0"/>
        <path d="M198.628 136.107V45.5275C198.628 45.4645 198.653 45.404 198.698 45.3594C198.743 45.3148 198.803 45.2898 198.866 45.2898C198.929 45.2898 198.99 45.3148 199.034 45.3594C199.079 45.404 199.104 45.4645 199.104 45.5275V136.107C199.104 136.17 199.079 136.231 199.034 136.275C198.99 136.32 198.929 136.345 198.866 136.345C198.803 136.345 198.743 136.32 198.698 136.275C198.653 136.231 198.628 136.17 198.628 136.107Z" fill="#F0F0F0"/>
        <path d="M185.077 117.92C187.178 117.92 188.881 116.217 188.881 114.116C188.881 112.015 187.178 110.312 185.077 110.312C182.976 110.312 181.273 112.015 181.273 114.116C181.273 116.217 182.976 117.92 185.077 117.92Z" fill="#F0F0F0"/>
        <path d="M92.5956 10.6984C94.6964 10.6984 96.3995 8.99538 96.3995 6.89456C96.3995 4.79375 94.6964 3.0907 92.5956 3.0907C90.4948 3.0907 88.7917 4.79375 88.7917 6.89456C88.7917 8.99538 90.4948 10.6984 92.5956 10.6984Z" fill="#F0F0F0"/>
        <path d="M91.055 159.762C91.0551 159.794 91.049 159.825 91.0371 159.854C91.0252 159.882 91.0077 159.909 90.9856 159.931C90.9635 159.953 90.9373 159.97 90.9084 159.982C90.8795 159.994 90.8485 160 90.8173 160H0.237742C0.174689 160 0.114218 159.975 0.0696327 159.931C0.0250475 159.886 0 159.825 0 159.762C0 159.699 0.0250475 159.639 0.0696327 159.594C0.114218 159.55 0.174689 159.525 0.237742 159.525H90.8173C90.8485 159.525 90.8795 159.531 90.9084 159.543C90.9373 159.554 90.9635 159.572 90.9856 159.594C91.0077 159.616 91.0252 159.642 91.0371 159.671C91.049 159.7 91.0551 159.731 91.055 159.762Z" fill="#CACACA"/>
        <path d="M98.3775 86.9061C90.4903 79.095 85.8817 68.5682 85.4922 57.4746L86.4426 57.4404C86.8237 68.2928 91.3322 78.5905 99.0479 86.2316L98.3775 86.9061Z" fill="#3F3D56"/>
        <path d="M108.579 76.7217L107.89 77.3731C102.635 71.8251 98.927 64.997 97.134 57.5693L97.5954 57.4575H98.085C99.8484 64.6773 103.462 71.3134 108.569 76.7122L108.579 76.7217Z" fill="#3F3D56"/>
        <path d="M117.746 67.5972L117.39 67.8944L117.383 67.9015L117.017 68.2058C114.349 65.0151 112.151 61.4599 110.488 57.6477L110.923 57.4575H111.444C113.054 61.1027 115.167 64.5045 117.72 67.5639C117.728 67.5756 117.737 67.5867 117.746 67.5972Z" fill="#3F3D56"/>
    </svg>

</template>
