<template>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="m-auto">
        <g clip-path="url(#clip)">
            <path id="icon" stroke="currentColor" d="M14.6667 8C14.6667 11.6819 11.6819 14.6667 8.00001 14.6667C4.31811 14.6667 2.00001 11 2.00001 11M1.33334 8C1.33334 4.3181 4.29631 1.33333 8.00001 1.33333C12.4445 1.33333 14.6667 5 14.6667 5M14.6667 5V2.66667M14.6667 5H12.3333M2.00001 11H4.33334M2.00001 11V13.3333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
        <defs>
            <clipPath id="clip">
                <rect width="16" height="16" fill="white"/>
            </clipPath>
        </defs>
    </svg>
</template>
