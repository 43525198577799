<!-- eslint-disable max-len -->
<template>
    <svg width="171" height="150" viewBox="0 0 171 150" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.54129 30.7337L11.9721 30.24L15.4283 21.847L9.25684 18.3909L6.54129 30.7337Z" fill="#FFB8B8"/>
        <path d="M56.3825 53.7752C56.5631 53.3249 56.634 52.838 56.5895 52.3549C56.545 51.8717 56.3864 51.406 56.1266 50.9962C55.8668 50.5864 55.5134 50.2442 55.0954 49.9977C54.6774 49.7513 54.2068 49.6077 53.7225 49.5789L45.2903 49.076L44.0487 53.2046L52.7292 55.5886C53.4546 55.7878 54.2279 55.713 54.9017 55.3786C55.5755 55.0442 56.1026 54.4734 56.3825 53.7752Z" fill="#FFB8B8"/>
        <path d="M47.3282 55.6578L47.2354 47.9108L28.5056 44.9222L14.0764 28.3735C13.3257 27.5125 12.2883 26.9527 11.1565 26.798C10.0247 26.6432 8.87513 26.9039 7.9208 27.5317C7.34298 27.9119 6.85571 28.4143 6.49342 29.0035C6.13114 29.5927 5.90268 30.2542 5.82419 30.9414C5.74571 31.6286 5.81911 32.3246 6.03922 32.9803C6.25933 33.636 6.62077 34.2354 7.09799 34.736L19.0019 49.3856L47.3282 55.6578Z" fill="#1E92FC"/>
        <path d="M2.34467 142.808H7.28184L19.5279 87.4404L32.2145 104.544L39.6203 141.573H44.3106L43.3232 101.829L26.7837 59.3694H10.491C6.03863 67.4271 2.54593 75.7333 6.54126 84.3021L2.34467 142.808Z" fill="#2F2E41"/>
        <path d="M36.5937 146.171C36.5391 146.345 36.5224 146.53 36.5446 146.711C36.5668 146.893 36.6275 147.068 36.7225 147.224C36.8176 147.38 36.9449 147.515 37.0959 147.618C37.2469 147.722 37.4182 147.792 37.5984 147.824L49.6797 149.968C50.1571 150.055 50.65 149.964 51.0654 149.714C51.4808 149.463 51.79 149.068 51.9347 148.605C52.0755 148.162 52.0559 147.684 51.8792 147.255C51.7025 146.825 51.3801 146.472 50.9686 146.256C48.3122 144.892 46.026 142.905 44.3059 140.464C42.9781 141.7 41.494 141.584 39.9181 140.673L38.2465 140.882L36.5937 146.171Z" fill="#2F2E41"/>
        <path d="M0.0586555 146.171C0.0040663 146.345 -0.0126865 146.53 0.00951805 146.711C0.0317226 146.893 0.092371 147.068 0.187428 147.224C0.282484 147.38 0.409768 147.515 0.560802 147.618C0.711836 147.722 0.883154 147.792 1.06335 147.824L13.1447 149.968C13.6221 150.055 14.1149 149.964 14.5303 149.714C14.9457 149.463 15.2549 149.068 15.3997 148.605C15.5405 148.162 15.5208 147.684 15.3441 147.255C15.1674 146.825 14.845 146.472 14.4335 146.256C11.7771 144.892 9.49091 142.905 7.77086 140.464C6.44304 141.7 4.95896 141.584 3.38302 140.673L1.71147 140.882L0.0586555 146.171Z" fill="#2F2E41"/>
        <path d="M15.5516 24.9326C19.369 24.9326 22.4636 21.838 22.4636 18.0206C22.4636 14.2031 19.369 11.1085 15.5516 11.1085C11.7342 11.1085 8.63956 14.2031 8.63956 18.0206C8.63956 21.838 11.7342 24.9326 15.5516 24.9326Z" fill="#FFB8B8"/>
        <path d="M9.3801 61.2209L27.4008 60.7272L22.1085 44.0709C25.3974 38.7984 22.0874 33.5313 17.403 28.2653L13.7001 25.7967L7.77551 25.303C5.21038 28.0478 3.80683 31.6786 3.85882 35.4351C3.97613 43.479 5.40043 52.2356 9.3801 61.2209Z" fill="#1E92FC"/>
        <path d="M4.1961 15.3052C4.1961 17.9948 5.94274 22.1599 8.14583 23.9453C9.62815 25.1466 11.5871 25.096 12.3424 23.4516C13.1172 21.7647 15.788 17.7995 20.4888 15.3052C26.7552 11.9802 18.2665 6.29151 12.3424 7.15892C7.8908 7.81073 4.1961 10.8062 4.1961 15.3052Z" fill="#2F2E41"/>
        <path d="M8.39268 11.6024C11.1194 11.6024 13.3299 9.39192 13.3299 6.6652C13.3299 3.93847 11.1194 1.72803 8.39268 1.72803C5.66596 1.72803 3.45551 3.93847 3.45551 6.6652C3.45551 9.39192 5.66596 11.6024 8.39268 11.6024Z" fill="#2F2E41"/>
        <path d="M14.0704 4.93717C14.07 3.69247 13.5991 2.49394 12.7521 1.5818C11.9052 0.669669 10.7448 0.111327 9.50357 0.0186934C9.62624 0.00956449 9.74886 0 9.87385 0C11.1833 0 12.4391 0.520164 13.365 1.44606C14.2909 2.37196 14.811 3.62775 14.811 4.93717C14.811 6.24659 14.2909 7.50237 13.365 8.42827C12.4391 9.35417 11.1833 9.87434 9.87385 9.87434C9.74886 9.87434 9.62624 9.86477 9.50357 9.85564C10.7448 9.763 11.9052 9.20466 12.7521 8.29253C13.5991 7.38039 14.07 6.18187 14.0704 4.93717Z" fill="#2F2E41"/>
        <path d="M15.675 20.4892C16.2885 20.4892 16.7859 19.826 16.7859 19.008C16.7859 18.19 16.2885 17.5269 15.675 17.5269C15.0615 17.5269 14.5642 18.19 14.5642 19.008C14.5642 19.826 15.0615 20.4892 15.675 20.4892Z" fill="#FFB8B8"/>
        <path d="M155.561 133.753H78.7043L62.4292 56.8162H48.5395V53.3641H65.2274L81.5026 130.301H155.561V133.753Z" fill="#3F3D56"/>
        <path d="M89.9672 147.809C94.0529 147.809 97.365 144.497 97.365 140.411C97.365 136.326 94.0529 133.013 89.9672 133.013C85.8815 133.013 82.5694 136.326 82.5694 140.411C82.5694 144.497 85.8815 147.809 89.9672 147.809Z" fill="#3F3D56"/>
        <path d="M145.204 147.809C149.29 147.809 152.602 144.497 152.602 140.411C152.602 136.326 149.29 133.013 145.204 133.013C141.118 133.013 137.806 136.326 137.806 140.411C137.806 144.497 141.118 147.809 145.204 147.809Z" fill="#3F3D56"/>
        <path d="M154.159 132.128H79.3825L66.1888 66.9266H166.541C167.053 66.9267 167.559 67.0377 168.024 67.2519C168.489 67.4662 168.902 67.7786 169.235 68.1678C169.568 68.5569 169.813 69.0136 169.952 69.5063C170.092 69.999 170.123 70.516 170.043 71.0219L157.661 129.133C157.534 129.969 157.11 130.731 156.467 131.281C155.824 131.831 155.005 132.131 154.159 132.128ZM80.1945 131.141H154.159C154.77 131.144 155.361 130.927 155.825 130.53C156.289 130.133 156.594 129.583 156.687 128.98L169.069 70.8688C169.126 70.5036 169.104 70.1304 169.003 69.7748C168.902 69.4191 168.726 69.0895 168.486 68.8086C168.245 68.5277 167.947 68.3022 167.611 68.1476C167.276 67.993 166.911 67.913 166.541 67.913H67.3861L80.1945 131.141Z" fill="#3F3D56"/>
        <path d="M93.9194 131.919L85.279 67.5001L86.252 67.3394L94.8924 131.758L93.9194 131.919Z" fill="#3F3D56"/>
        <path d="M141.963 131.424L140.989 131.266L149.377 67.3408L150.351 67.4987L141.963 131.424Z" fill="#3F3D56"/>
        <path d="M119.073 67.4199H118.086V131.839H119.073V67.4199Z" fill="#3F3D56"/>
        <path d="M71.2274 84.6814L71.225 85.6677L166.704 85.8989L166.707 84.9125L71.2274 84.6814Z" fill="#3F3D56"/>
        <path d="M159.969 115.151L76.0883 115.725L76.095 116.711L159.976 116.138L159.969 115.151Z" fill="#3F3D56"/>
        <path d="M50.0192 57.5562C53.0153 57.5562 55.4442 56.2313 55.4442 54.597C55.4442 52.9628 53.0153 51.6379 50.0192 51.6379C47.023 51.6379 44.5941 52.9628 44.5941 54.597C44.5941 56.2313 47.023 57.5562 50.0192 57.5562Z" fill="#3F3D56"/>
    </svg>
</template>
