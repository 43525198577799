<!-- eslint-disable max-len -->
<template>
    <svg width="186" height="170" viewBox="0 0 186 170" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M179.203 0.101318H0.0761719V114.822H179.203V0.101318Z" fill="#E6E6E6"/>
        <path d="M179.127 0H0V7.60988H179.127V0Z" fill="#1E92FC"/>
        <path d="M122.117 12.5026H43.342V23.6159H122.117V12.5026Z" fill="white"/>
        <path d="M110.049 14.2507H55.2886V21.9045H110.049V14.2507Z" fill="#F5F5F5"/>
        <path d="M116.763 15.5136C116.425 15.1612 115.993 14.9128 115.518 14.7975C115.043 14.6822 114.545 14.705 114.082 14.863C113.62 15.021 113.212 15.3077 112.907 15.6895C112.601 16.0713 112.412 16.5323 112.359 17.0183C112.307 17.5042 112.395 17.995 112.612 18.4329C112.829 18.8708 113.167 19.2377 113.585 19.4903C114.003 19.743 114.485 19.871 114.974 19.8592C115.462 19.8475 115.938 19.6966 116.343 19.4241L118.199 21.3547C118.21 21.3665 118.224 21.376 118.239 21.3826C118.254 21.3892 118.27 21.3928 118.287 21.3931C118.303 21.3934 118.32 21.3905 118.335 21.3844C118.35 21.3784 118.364 21.3694 118.376 21.358L118.838 20.9146C118.85 20.9032 118.86 20.8896 118.866 20.8745C118.873 20.8595 118.876 20.8433 118.877 20.8269C118.877 20.8104 118.874 20.7941 118.868 20.7788C118.862 20.7635 118.853 20.7496 118.841 20.7378L116.986 18.8061C117.342 18.3186 117.515 17.7214 117.474 17.1193C117.433 16.5172 117.182 15.9487 116.763 15.5136ZM116.185 18.6176C115.924 18.8693 115.593 19.0377 115.235 19.1015C114.878 19.1653 114.509 19.1217 114.177 18.9761C113.844 18.8305 113.562 18.5896 113.366 18.2837C113.17 17.9778 113.07 17.6208 113.077 17.2577C113.084 16.8946 113.199 16.5417 113.406 16.2438C113.614 15.9458 113.905 15.7161 114.243 15.5837C114.582 15.4514 114.951 15.4222 115.306 15.5C115.661 15.5778 115.985 15.759 116.236 16.0208C116.574 16.3718 116.758 16.8425 116.748 17.3292C116.739 17.816 116.536 18.279 116.185 18.6165V18.6176Z" fill="#1E92FC"/>
        <mask id="mask0_2705_261" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="45" y="14" width="8" height="8">
        <path d="M52.5467 17.401H49.1812V18.7972H51.1192C50.9386 19.684 50.1828 20.1935 49.1812 20.1935C48.6149 20.1935 48.0719 19.9685 47.6715 19.5681C47.2711 19.1677 47.0462 18.6247 47.0462 18.0584C47.0462 17.4922 47.2711 16.9491 47.6715 16.5487C48.0719 16.1483 48.6149 15.9234 49.1812 15.9234C49.6665 15.9227 50.1369 16.091 50.5116 16.3994L51.5629 15.3482C51.1242 14.962 50.5983 14.688 50.0304 14.5498C49.4625 14.4116 48.8695 14.4133 48.3024 14.5547C47.7353 14.6961 47.211 14.973 46.7745 15.3617C46.338 15.7503 46.0023 16.2391 45.7963 16.7861C45.5903 17.333 45.5201 17.9218 45.5917 18.5019C45.6633 19.0819 45.8746 19.6359 46.2075 20.1164C46.5404 20.5968 46.9849 20.9892 47.5028 21.26C48.0208 21.5308 48.5967 21.6719 49.1812 21.6711C50.9877 21.6711 52.6313 20.3573 52.6313 18.0592C52.6275 17.8374 52.5992 17.6166 52.5467 17.401Z" fill="white"/>
        </mask>
        <g mask="url(#mask0_2705_261)">
        <path d="M45.0742 20.1929V15.9229L47.8661 18.0576L45.0742 20.1929Z" fill="#FBBC05"/>
        <path d="M45.0742 15.9228L47.8661 18.0576L49.016 17.0559L52.955 16.4155V14.1163H45.0742V15.9228Z" fill="#EA4335"/>
        <path d="M45.0742 20.1929L50.0014 16.4155L51.299 16.5772L52.955 14.1163V21.9993H45.0742V20.1929Z" fill="#34A853"/>
        <path d="M52.955 21.9994L47.8639 18.0576L47.2068 17.5649L52.955 15.9229V21.9994Z" fill="#4285F4"/>
        </g>
        <path d="M5.65519 5.30123C6.43422 5.30123 7.06576 4.6697 7.06576 3.89067C7.06576 3.11163 6.43422 2.4801 5.65519 2.4801C4.87616 2.4801 4.24463 3.11163 4.24463 3.89067C4.24463 4.6697 4.87616 5.30123 5.65519 5.30123Z" fill="white"/>
        <path d="M11.009 5.30123C11.788 5.30123 12.4195 4.6697 12.4195 3.89067C12.4195 3.11163 11.788 2.4801 11.009 2.4801C10.2299 2.4801 9.59839 3.11163 9.59839 3.89067C9.59839 4.6697 10.2299 5.30123 11.009 5.30123Z" fill="white"/>
        <path d="M16.3622 5.30123C17.1413 5.30123 17.7728 4.6697 17.7728 3.89067C17.7728 3.11163 17.1413 2.4801 16.3622 2.4801C15.5832 2.4801 14.9517 3.11163 14.9517 3.89067C14.9517 4.6697 15.5832 5.30123 16.3622 5.30123Z" fill="white"/>
        <path d="M174.202 28.339H5.27881V114.745H174.202V28.339Z" fill="white"/>
        <path d="M59.0081 36.076H25.9773V71.9116H59.0081V36.076Z" fill="#E6E6E6"/>
        <path d="M106.155 36.076H73.124V71.9116H106.155V36.076Z" fill="#E6E6E6"/>
        <path d="M153.301 36.076H120.27V71.9116H153.301V36.076Z" fill="#E6E6E6"/>
        <path d="M59.0081 78.9537H25.9773V114.789H59.0081V78.9537Z" fill="#E6E6E6"/>
        <path d="M106.155 78.9537H73.124V114.789H106.155V78.9537Z" fill="#E6E6E6"/>
        <path d="M153.301 78.9537H120.27V114.789H153.301V78.9537Z" fill="#E6E6E6"/>
        <path d="M81.1076 88.1638L87.1427 85.7777L87.8445 97.7076C87.8445 97.7076 89.1076 101.357 88.6867 102.76C88.6867 102.76 88.9673 105.146 88.2655 105.287C87.5636 105.427 85.5982 105.708 85.4593 105.567C85.3204 105.427 85.319 105.006 85.319 105.006C85.319 105.006 83.9154 105.988 83.7751 107.111C83.7751 107.111 77.8803 108.935 77.74 107.251C77.5997 105.567 80.6873 104.163 80.6873 104.163L83.0733 100.514L81.1076 88.1638Z" fill="white"/>
        <path d="M93.8799 88.1638L99.9142 85.7777L100.616 97.7076C100.616 97.7076 101.879 101.357 101.458 102.76C101.458 102.76 101.739 105.146 101.037 105.287C100.335 105.427 98.3697 105.708 98.2308 105.567C98.0919 105.427 98.0905 105.006 98.0905 105.006C98.0905 105.006 96.6869 105.988 96.5466 107.111C96.5466 107.111 90.6518 108.935 90.5115 107.251C90.3712 105.567 93.4587 104.163 93.4587 104.163L95.8448 100.514L93.8799 88.1638Z" fill="white"/>
        <path d="M94.4695 41.5388C94.4695 41.5388 93.1846 45.1296 94.6735 46.7488L93.4321 51.0144C93.4321 51.0144 97.8425 64.2454 96.5825 66.0935C96.5825 66.0935 91.8782 68.4457 82.4685 65.2534L86.2907 50.4262L85.7447 44.1677L86.5007 41.2274L88.1808 41.0607C88.1808 41.0607 87.1728 44.8409 89.0209 45.4291C90.8691 46.0172 92.672 41.1551 92.672 41.1551L94.4695 41.5388Z" fill="#3F3D56"/>
        <path d="M35.1238 64.7177L36.7455 65.3812L44.4854 55.2089L46.9178 67.1502L48.5395 66.8554C49.5798 56.5603 50.4288 46.0595 49.3872 41.0929L43.3796 40.8348L40.6524 49.5341L39.3255 55.4311L35.1238 64.7177Z" fill="white"/>
        <path d="M133.548 41.0116C133.548 41.0116 131.335 44.0883 131.443 45.33C131.551 46.5716 132.955 66.8141 132.955 66.8141L136.248 66.976L135.275 52.4014L136.085 48.4609L138.082 66.8141L142.131 66.9221L138.514 43.117L137.812 41.0119L133.548 41.0116Z" fill="white"/>
        <path d="M146.057 91.1345C145.47 90.4293 144.46 90.0481 143.49 89.842C143.517 89.7539 140.54 89.1385 140.489 89.2652L139.366 88.3708L135.916 90.162L133.978 88.8423L133.078 89.1618C133.114 89.0029 130.282 89.4936 130.282 89.4936C129.741 89.513 129.05 89.5469 128.277 89.6064C125.568 89.8148 125.36 96.8301 125.36 96.8301C126.606 96.3202 127.935 96.042 129.281 96.0091L130.013 104.4C133.778 104.077 137.727 104.452 141.821 105.372L143.74 98.7763L148.21 97.0379C148.21 97.0379 147.447 92.8012 146.057 91.1345Z" fill="#3F3D56"/>
        <path d="M50.1323 87.139C49.6481 86.5581 48.816 86.2439 48.0166 86.0741C48.0405 86.0013 45.5859 85.4945 45.5439 85.599L44.6187 84.8622L41.7768 86.338L40.1795 85.2509L39.4377 85.5143C39.468 85.3834 37.1344 85.7874 37.1344 85.7874C36.6899 85.8035 36.1192 85.8313 35.4822 85.8804C33.2503 86.0521 33.0786 91.8319 33.0786 91.8319C34.1055 91.4118 35.2004 91.1826 36.3095 91.1554L36.9127 108.079C40.0153 107.813 43.2693 108.123 46.6411 108.881L48.2225 93.4359L51.9058 92.0036C51.9058 92.0036 51.2767 88.5129 50.1323 87.139Z" fill="white"/>
        <path d="M130.302 73.5487V66.6029C130.302 64.1344 131.282 61.767 133.028 60.0215C134.773 58.276 137.141 57.2954 139.609 57.2954C142.078 57.2954 144.445 58.276 146.191 60.0215C147.936 61.767 148.917 64.1344 148.917 66.6029V73.5487C148.916 73.8802 148.784 74.198 148.55 74.4324C148.316 74.6667 147.998 74.7986 147.666 74.799H131.552C131.221 74.7986 130.903 74.6667 130.668 74.4324C130.434 74.198 130.302 73.8802 130.302 73.5487Z" fill="#2F2E41"/>
        <path d="M153.372 163.941L150.089 164.85L145.022 152.62L149.868 151.279L153.372 163.941Z" fill="#FFB8B8"/>
        <path d="M147.485 164.561L153.816 162.808L154.919 166.795L144.602 169.65C144.457 169.127 144.417 168.58 144.483 168.041C144.549 167.502 144.721 166.981 144.989 166.508C145.257 166.036 145.615 165.62 146.043 165.286C146.471 164.952 146.961 164.705 147.485 164.561Z" fill="#2F2E41"/>
        <path d="M119.146 166.466H115.74L114.12 153.328H119.147L119.146 166.466Z" fill="#FFB8B8"/>
        <path d="M113.307 165.493H119.876V169.629H109.171C109.171 169.086 109.278 168.548 109.486 168.046C109.694 167.544 109.999 167.088 110.383 166.704C110.767 166.32 111.223 166.016 111.724 165.808C112.226 165.6 112.764 165.493 113.307 165.493Z" fill="#2F2E41"/>
        <path d="M115.039 93.041C115.282 93.3692 115.594 93.6401 115.953 93.8346C116.313 94.0292 116.71 94.1426 117.118 94.1669C117.525 94.1912 117.934 94.1258 118.313 93.9753C118.693 93.8248 119.035 93.5929 119.315 93.2959L128.37 97.3684L127.547 92.2753L119.074 89.1888C118.544 88.7508 117.872 88.5226 117.185 88.5472C116.497 88.5719 115.843 88.8478 115.346 89.3226C114.848 89.7974 114.542 90.4382 114.486 91.1236C114.429 91.8089 114.626 92.4912 115.039 93.041Z" fill="#FFB8B8"/>
        <path d="M133.853 123.669C131.008 123.669 128.063 123.245 125.587 121.985C124.273 121.329 123.109 120.409 122.167 119.282C121.224 118.156 120.525 116.847 120.112 115.438C118.822 111.354 120.448 107.341 122.021 103.461C122.995 101.057 123.916 98.7863 124.155 96.5479L124.238 95.7532C124.61 92.1837 124.931 89.1009 126.71 87.9447C127.632 87.3453 128.871 87.3111 130.5 87.8399L145.793 92.808L145.231 121.839L145.138 121.87C145.058 121.897 139.667 123.669 133.853 123.669Z" fill="#2F2E41"/>
        <path d="M130.232 78.8021C130.232 78.8021 137.734 76.5795 143.568 77.413C143.568 77.413 140.234 95.75 141.345 101.862C142.457 107.975 122.036 104.224 126.203 98.3894L127.593 91.4436C127.593 91.4436 124.814 88.6653 127.315 85.3312L130.232 78.8021Z" fill="#1E92FC"/>
        <path d="M126.543 123.119L117.297 121.385L120.525 96.5448C120.742 95.8513 125.742 80.1129 128.013 79.2613C129.662 78.6807 131.348 78.2122 133.06 77.8591L133.389 77.7933L131.536 80.5741L124.155 98.2595L126.543 123.119Z" fill="#2F2E41"/>
        <path d="M121.725 163.422L109.78 161.431L116.483 134.058L126.459 96.6233L126.557 97.288C126.565 97.3375 127.497 102.228 141.324 100.058L141.446 100.039L141.479 100.157L158.192 159.208L144.58 161.476L132.166 118.393L121.725 163.422Z" fill="#2F2E41"/>
        <path d="M138.282 127.767L138.29 127.557C138.298 127.324 139.12 104.115 138.845 94.7816C138.57 85.4161 141.602 77.3056 141.633 77.2247L141.657 77.1596L141.724 77.1403C145.647 76.0196 149.051 79.3645 149.084 79.3984L149.132 79.4462L148.015 88.6637L152.798 122.182L138.282 127.767Z" fill="#2F2E41"/>
        <path d="M137.697 74.6374C141.466 74.6374 144.521 71.5823 144.521 67.8135C144.521 64.0448 141.466 60.9896 137.697 60.9896C133.928 60.9896 130.873 64.0448 130.873 67.8135C130.873 71.5823 133.928 74.6374 137.697 74.6374Z" fill="#FFB8B8"/>
        <path d="M128.1 67.1585C128.102 65.2065 128.879 63.3351 130.259 61.9548C131.639 60.5745 133.511 59.7981 135.463 59.7959H136.852C138.804 59.7981 140.675 60.5745 142.056 61.9548C143.436 63.3351 144.212 65.2065 144.214 67.1585V67.2974H141.279L140.277 64.4936L140.077 67.2974H138.56L138.055 65.8827L137.954 67.2974H128.1V67.1585Z" fill="#2F2E41"/>
        <path d="M137.025 75.5801C136.887 75.3935 136.805 75.1717 136.788 74.9403C136.77 74.709 136.819 74.4774 136.927 74.2723C138.399 71.4723 140.46 66.2984 137.725 63.1077L137.528 62.8784H145.471V74.803L138.255 76.0761C138.182 76.089 138.108 76.0955 138.034 76.0956C137.837 76.0956 137.643 76.0489 137.468 75.9593C137.292 75.8696 137.14 75.7397 137.025 75.5801Z" fill="#2F2E41"/>
        <path d="M105.047 26.3466C99.4895 21.6569 92.6884 18.6836 85.4716 17.7887C78.2549 16.8938 70.9336 18.1157 64.3986 21.3059C57.8636 24.4961 52.3968 29.5169 48.6633 35.7574C44.9298 41.9979 43.0907 49.189 43.3697 56.4557C43.6486 63.7224 46.0337 70.7514 50.2346 76.6873C54.4356 82.6232 60.2712 87.2101 67.0315 89.8898C73.7918 92.5696 81.1853 93.2267 88.3122 91.7811C95.4391 90.3355 101.992 86.8496 107.174 81.7475L153.188 120.574C153.889 121.166 154.795 121.456 155.709 121.379C156.622 121.303 157.468 120.866 158.06 120.166C158.652 119.466 158.942 118.56 158.865 117.646C158.789 116.732 158.352 115.887 157.652 115.295L157.647 115.29L111.633 76.4636C116.978 68.7918 119.266 59.4002 118.048 50.1295C116.83 40.8588 112.193 32.3771 105.047 26.3466ZM102.081 72.7248C98.4962 76.9732 93.7322 80.0631 88.3914 81.6039C83.0505 83.1447 77.3727 83.0672 72.076 81.3811C66.7792 79.695 62.1014 76.4761 58.634 72.1314C55.1666 67.7868 53.0655 62.5115 52.5962 56.9727C52.127 51.4339 53.3107 45.8803 55.9978 41.0143C58.6848 36.1482 62.7544 32.1882 67.6921 29.6351C72.6297 27.082 78.2135 26.0503 83.7374 26.6707C89.2614 27.291 94.4773 29.5354 98.7256 33.1202C101.546 35.5003 103.871 38.4128 105.566 41.6913C107.261 44.9697 108.294 48.55 108.606 52.2276C108.917 55.9052 108.501 59.6082 107.382 63.1251C106.262 66.6421 104.461 69.904 102.081 72.7248Z" fill="#3F3D56"/>
        <path opacity="0.3" d="M62.4759 76.0801C57.0843 71.5302 53.6008 65.1191 52.7173 58.1197C51.8338 51.1204 53.6148 44.0447 57.7067 38.2977C57.1694 38.8389 56.6499 39.4057 56.1483 39.998C53.7681 42.8188 51.9668 46.0808 50.8473 49.5977C49.7278 53.1147 49.3119 56.8177 49.6235 60.4953C49.9351 64.1729 50.968 67.7532 52.6632 71.0316C54.3584 74.3101 56.6828 77.2226 59.5035 79.6027C62.3243 81.9829 65.5863 83.7842 69.1032 84.9037C72.6202 86.0232 76.3231 86.4391 80.0008 86.1275C83.6784 85.8159 87.2587 84.783 90.5371 83.0878C93.8156 81.3926 96.728 79.0682 99.1082 76.2475C99.6087 75.6543 100.08 75.0469 100.522 74.4251C95.5454 79.4256 88.8703 82.3715 81.822 82.6781C74.7738 82.9847 67.868 80.6295 62.4759 76.0801Z" fill="black"/>
        <path d="M139.888 104.971C140.284 105.068 140.698 105.076 141.098 104.996C141.499 104.917 141.877 104.75 142.206 104.508C142.535 104.266 142.807 103.955 143.003 103.596C143.199 103.238 143.314 102.841 143.339 102.433L152.906 99.7779L149.017 96.3873L140.529 99.4287C139.841 99.4308 139.178 99.685 138.665 100.143C138.153 100.601 137.826 101.232 137.747 101.915C137.668 102.598 137.842 103.286 138.237 103.85C138.631 104.413 139.219 104.812 139.888 104.971Z" fill="#FFB8B8"/>
        <path d="M143.864 103.565L143.282 98.0346L151.273 93.6256L146.063 87.3187L146.913 80.2341L149.03 79.3268L149.096 79.4114C150.094 80.6951 158.849 91.992 158.849 93.3885C158.849 94.8228 157.174 99.035 154.883 100.181C152.672 101.286 144.372 103.434 144.02 103.525L143.864 103.565Z" fill="#2F2E41"/>
        <path d="M185.328 170H79.4731C79.3995 170 79.3288 169.971 79.2767 169.919C79.2246 169.867 79.1953 169.796 79.1953 169.722C79.1953 169.648 79.2246 169.578 79.2767 169.526C79.3288 169.474 79.3995 169.444 79.4731 169.444H185.328C185.402 169.444 185.472 169.474 185.524 169.526C185.576 169.578 185.606 169.648 185.606 169.722C185.606 169.796 185.576 169.867 185.524 169.919C185.472 169.971 185.402 170 185.328 170Z" fill="#CCCCCC"/>
    </svg>

</template>
