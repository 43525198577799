<template>
    <svg width="32" height="44" viewBox="0 0 32 44" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.33429 27.3339V32.6608V32.665V38.021L10.6688 35.3181V30.0368L5.33429 27.3339Z" fill="#BD171D"/>
        <path d="M10.6657 30.0371V35.3599V35.3682V40.7201L16.0002 43.423V38.0711V38.067V32.711L10.6657 30.0371Z" fill="#10799B"/>
        <path d="M15.9959 5.96356L21.3269 3.25649L16.0031 0.603394L10.6614 3.25649L15.9959 5.96356Z" fill="#FFF580"/>
        <path d="M15.9962 5.96378V11.2617L21.3343 13.9646L21.3272 8.61687V3.26086L15.9962 5.96378Z" fill="#E3D200"/>
        <path d="M10.6614 8.5546L15.9959 11.2617V5.96378L10.6614 3.26086L10.6686 8.55875" fill="#FFEC00"/>
        <path d="M21.3345 8.56274V13.9188V19.2748V24.6349V29.9411V29.9453V19.3121L26.6655 21.9777V32.6482V32.644V27.2922V21.9777V16.6217V11.2657L21.3345 8.56274Z" fill="#10799B"/>
        <path d="M26.6655 11.2658L32 8.56288L26.6762 5.90564L21.3345 8.56288L26.6655 11.2658Z" fill="#4CC7F3"/>
        <path d="M21.3345 24.6926L26.6655 21.9772L21.3345 19.3116V24.6926Z" fill="#4CC7F3"/>
        <path d="M10.6652 19.3205L5.33429 21.9777V16.6217L10.6652 19.3205Z" fill="#BD171D"/>
        <path d="M10.6652 24.6767L5.33429 21.9738L10.6652 19.3207V24.6767Z" fill="#F26066"/>
        <path d="M10.6655 13.9642L5.34877 11.2613L0 8.55835V13.9144V19.2704V29.9575V35.3135L5.34877 38.0164V32.6645V27.3334L10.6975 30.0364L16.0463 32.7102V16.6671L10.6655 13.9642ZM10.6655 24.6762L5.34877 21.9733V16.6214L10.6975 19.3202V24.6679L10.6655 24.6762Z" fill="#ED1C24"/>
        <path d="M15.9998 22.0234V27.3794V32.6856V32.7105V38.0374V38.0706V43.4225L21.3343 40.7196V35.3677V35.3594V30.0325V30.0242L26.6652 27.3378V32.644V32.6482V38L31.9997 35.3013V29.9453V29.9411V24.6349V24.5893V19.2748V13.9188V8.56274L26.6652 11.2657V16.6217V21.9777L21.3343 24.6931V24.6765V19.3204V13.9644L15.9998 16.6673" fill="#00AFEE"/>
        <path d="M21.3345 13.9648L16 11.2619L10.669 8.55898L5.32381 5.90173L0 8.55898L5.33451 11.2619L10.6655 13.9648L16 16.6677L21.3345 13.9648Z" fill="#F26066"/>
        <path d="M21.3345 29.9413V29.9455V35.3015L26.6655 38.0002V32.6484V32.6442V32.3038V27.2924" fill="#10799B"/>
    </svg>
</template>
